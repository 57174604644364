import PropTypes from 'prop-types';

// material-ui
import { FormControlLabel, Radio } from '@mui/material';

// ==============================|| CALENDAR COLOR PALETTE ||============================== //

const ColorPalette = ({ color, label, value, disabled }) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ color, '&.Mui-checked': { color } }} />}
    label={label || ''}
    sx={{ pr: label ? 1 : 0 }}
    disabled={disabled}
  />
);

ColorPalette.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string
};

export default ColorPalette;
