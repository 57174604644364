import React, { useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { useLocale } from './contexts/LocaleContext';
import { useIntl } from 'react-intl'; // Import the useIntl hook
import useAuth from './hooks/useAuth';
import Header from './header';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from './DataTable';
import { Grid, Autocomplete, TextField } from '@mui/material';

const Employee = () => {
  const { id } = useParams();
  const intl = useIntl();
  const [jobs, setJobs] = useState([])
  const [employees, setEmployees] = useState([])
  const { getToken } = useAuth();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();

  function localeFormat(id) {
    return intl.formatMessage({ id });
  }

  const handleChange = (event, newValue) => {
    if (newValue) {
      setSelectedEmployee(newValue);
      // Update the location with the selected employee's ID
      navigate(`/employee/${newValue.id}`);
    }
  };


  const EmployeeDatalist = ({ employees, localeFormat }) => {
    return (
      <Autocomplete
        id="employees"
        name="employees"
        options={employees}
        getOptionLabel={(option) => option.employee_name + ' (' + option.id + ')'} // Adjust based on the employee object structure
        value={selectedEmployee}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label={localeFormat("Choose an employee")} />
        )}
      />
    );
  };

  async function fetchEmployees() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/employees`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setEmployees(data);

        // After fetching, set the selected employee based on the URL ID
        const selected = data.find((employee) => employee.id === parseInt(id));
        setSelectedEmployee(selected || null); // Set to null if no match found
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchJobs() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/jobs/${id}`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setJobs(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchJobs();
  }, [id]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <div>
      <div style={{ height: 'auto', width: '100%' }}>
        <Grid container>
          <Grid item xs={3}>
            <EmployeeDatalist localeFormat={localeFormat} employees={employees} />
          </Grid>
          <Grid item xs={12}>
            <DataTable data={jobs} localeFormat={localeFormat} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Employee;