import { useEffect, useRef, useState } from 'react';

// material-ui
import { Button, Dialog, useMediaQuery } from '@mui/material';

// third-party
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import { RRule } from 'rrule';

// redux
import { useDispatch, useSelector } from 'store';

// project imports
import MainCard from 'ui/MainCard';
import SubCard from 'ui/SubCard';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';
import AddEventForm from './AddEventForm';
import EventFilter from 'components/EventFilter';

// assets
import AddAlarmTwoToneIcon from '@mui/icons-material/AddAlarmTwoTone';

import { useIntl } from 'react-intl'; // Import the useIntl hook
// ==============================|| APPLICATION CALENDAR ||============================== //
import useAuth from 'hooks/useAuth';

import axios from 'utils/axios';
import { type } from '@testing-library/user-event/dist/type';

const Calendar = () => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const matchSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const intl = useIntl();
  const { getToken } = useAuth();

  function t(id) {
    return intl.formatMessage({ id });
  }

  // fetch events data
  const [events, setEvents] = useState([]);
  // const calendarState = useSelector((state) => state.calendar);
  const [visibleEvents, setVisibleEvents] = useState([]); // Filtered visible events

  // Function to set headers
  const setHeaders = async () => {
    const token = await getToken();
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const processEvents = (data) => {
    if (!data) {
      return;
    }
    // Parse and process events
    const processedEvents = data.flatMap(event => {
      return isEventRecurring(event);
    });
    // console.log(processedEvents)
    // Set processed events in state
    return processedEvents;
  }

  const isEventRecurring = (event) => {
    if (event.recurring !== false) {
      // Parse recurring events based on recurrence type
      return generateRecurringEvents(event);
    } else {
      // One-time event, return as is
      return [event];
    }
  }

  function isLastDayOfMonth(date) {
    const testDate = new Date(date);
    testDate.setDate(testDate.getDate() + 1);
    return testDate.getDate() === 1;
  }

  // Helper function to generate recurring events
  const generateRecurringEvents = (event) => {
    const interval = event.recurrenceType === 'quarterly' ? 3 : 1;
    const freq = event.recurrenceType === 'yearly' ? 'yearly' : 'monthly';

    const bymonthday = isLastDayOfMonth(event.start) == true ? [-1] : [new Date(event.start).getDate()];
    // console.log(bymonthday)
    return [{
      ...event,
      rrule: {
        freq: freq,
        interval: interval,
        dtstart: event.start,
        count: 12, // Optional, specify max occurrences
        bymonthday: bymonthday,
      }
    }];

  };

  const fetchJobsStandalone = async () => {
    try {
      let url = `${process.env.REACT_APP_URL}/api/jobs-standalone`;
      const headers = await setHeaders();
      const response = await axios.get(url, headers);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const mergeEventsAndJobs = async (events) => {
    const jobs = await fetchJobsStandalone();
    console.log(jobs)
    // Merge events and jobs, adding a `status` field
    const mergedArray = [
      ...events.map(event => ({
        ...event,
        color: event.status === 'In progress'
          ? 'orange' // Assign blue for "In progress"
          : event.status === 'Finished'
            ? 'green' // Assign green for "Finished"
            : '',
      })), // Add `status` to distinguish events
      ...jobs.map(job => ({
        ...job,
        status: 'job',
        color: 'green',
        start: job.start_time,
        // title: job.activity_id + ' ' + job.client_id
      })),
    ];
    console.log(mergedArray);
    changedEvents(mergedArray);
  }

  const changedEvents = (data) => {
    setEvents(data);
    setVisibleEvents(data);
  }



  // Fetch events
  const fetchEvents = async () => {
    try {
      const headers = await setHeaders();
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/calendar/events`, headers);

      const processedEvents = processEvents(response.data);
      // Set processed events in state
      mergeEventsAndJobs(processedEvents)
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  // Add an event
  const addEvent = async (newEvent) => {
    try {
      const headers = await setHeaders();
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/calendar/events/new`, newEvent, headers);

      // Process the new event before adding it to the state
      const processedEvent = isEventRecurring(response.data);

      changedEvents((prevEvents) => [...prevEvents, ...processedEvent]); // Add new event to state
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  const processUpdatedEvent = (event, updatedEvent) => {
    let updatedItem = { ...event, ...updatedEvent.update };

    // If the event is still recurring or becomes recurring
    if (updatedEvent.update.recurrenceType && updatedEvent.update.recurrenceType !== 'none') {
      // Generate new recurring events with the updated recurrence details
      const recurringEvents = generateRecurringEvents(updatedItem);
      // Use the first recurring event as the representative updated event (if you are only storing one main instance)
      updatedItem = recurringEvents[0]; // Or handle the array if needed
    }

    // If the event was recurring but is no longer recurring, remove 'rrule'
    if (event.rrule && (!updatedEvent.update.recurrenceType || updatedEvent.update.recurrenceType === 'none')) {
      delete updatedItem.rrule;
    }

    return updatedItem; // Return the updated event
  }

  // Update an event
  const updateEvent = async (updatedEvent) => {
    try {
      const headers = await setHeaders();
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/calendar/events/update`, updatedEvent, headers);
      let updatedEvents = events.map((event) => {
        // Check if this is the event we want to update
        if (event.id === updatedEvent.eventId) {
          return processUpdatedEvent(event, updatedEvent);
        }
        return event;
      });
      // events.map((event) =>
      //   event.id === updatedEvent.eventId ? { ...event, ...updatedEvent.update } : event
      // );
      changedEvents(updatedEvents);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  // Remove an event
  const removeEvent = async (eventId) => {
    try {
      const headers = await setHeaders();
      await axios.post(`${process.env.REACT_APP_URL}/api/calendar/events/remove`, { eventId }, headers);
      handleModalClose();
      changedEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId)); // Remove event from state
    } catch (error) {
      console.error('Error removing event:', error);
    }
  };

  useEffect(() => {
    console.log(events)
  }, [events]);

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Add dispatch to dependency array

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(matchSm ? 'listWeek' : 'dayGridMonth');

  // calendar toolbar events
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  // set calendar view
  useEffect(() => {
    handleViewChange(matchSm ? 'listWeek' : 'dayGridMonth');
  }, [matchSm]);

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // calendar event select/add/edit/delete
  const handleRangeSelect = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    setSelectedRange({
      start: arg.start,
      end: arg.end
    });
    setIsModalOpen(true);
  };

  const handleEventSelect = (arg) => {
    if (arg.event.id) {
      // console.log(arg.event.id)
      const selectEvent = events.find((_event) => _event.id == arg.event.id);
      // console.log(selectEvent)
      setSelectedEvent(selectEvent);
    } else {
      setSelectedEvent(null);
    }
    setIsModalOpen(true);
  };

  const handleEventUpdate = async ({ event }) => {
    try {
      dispatch(
        updateEvent({
          eventId: event.id,
          update: {
            allDay: event.allDay,
            start: event.start,
            end: event.end
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    setSelectedRange(null);
  };

  const handleEventCreate = async (data) => {
    addEvent(data);
    handleModalClose();
  };

  const handleUpdateEvent = async (eventId, update) => {
    updateEvent({ eventId, update });
    handleModalClose();
  };

  const handleEventDelete = async (id) => {
    try {
      dispatch(removeEvent(id));
      handleModalClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddClick = () => {
    setIsModalOpen(true);
  };

  // Handle filter change
  const handleFilterChange = (filters) => {
    // Update visibleEvents based on the selected filters
    const filteredEvents = filters.length
      ? events.filter((event) => filters.includes(event.status))
      : events; // Show all events if no filters are applied

    setVisibleEvents(filteredEvents);
  };


  return (
    <MainCard
      title={t("Tasks")}
      secondary={
        <Button color="secondary" variant="contained" onClick={handleAddClick}>
          <AddAlarmTwoToneIcon fontSize="small" sx={{ mr: 0.75 }} />
          {t("Add New Task")}
        </Button>
      }
    >
      <EventFilter onFilterChange={handleFilterChange} />
      <CalendarStyled>
        <Toolbar
          date={date}
          view={view}
          onClickNext={handleDateNext}
          onClickPrev={handleDatePrev}
          onClickToday={handleDateToday}
          onChangeView={handleViewChange}
        />
        <SubCard>
          <FullCalendar
            weekends
            editable
            droppable
            selectable
            events={visibleEvents}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            select={handleRangeSelect}
            eventDrop={handleEventUpdate}
            eventClick={handleEventSelect}
            eventResize={handleEventUpdate}
            height={matchSm ? 'auto' : 720}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
          />
        </SubCard>
      </CalendarStyled>

      {/* Dialog renders its body even if not open */}
      <Dialog maxWidth="sm" fullWidth onClose={handleModalClose} open={isModalOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {isModalOpen && (
          <AddEventForm
            event={selectedEvent}
            range={selectedRange}
            onCancel={handleModalClose}
            handleDelete={handleEventDelete}
            handleCreate={handleEventCreate}
            handleUpdate={handleUpdateEvent}
          />
        )}
      </Dialog>
    </MainCard>
  );
};

export default Calendar;
