import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const EventFilter = ({ onFilterChange }) => {
  const filterStatuses = ['job', 'In progress', 'Not started', 'Finished'];

  // Initialize selectedFilters with all filter statuses
  const [selectedFilters, setSelectedFilters] = useState(filterStatuses);

  const handleFilterChange = (status) => {
    const updatedFilters = selectedFilters.includes(status)
      ? selectedFilters.filter((filter) => filter !== status) // Remove status if unchecked
      : [...selectedFilters, status]; // Add status if checked

    setSelectedFilters(updatedFilters);

    // Notify the parent of the selected filters
    onFilterChange(updatedFilters);
  };

  return (
    <div>
      {filterStatuses.map((status) => (
        <FormControlLabel
          key={status}
          control={
            <Checkbox
              checked={selectedFilters.includes(status)}
              onChange={() => handleFilterChange(status)}
            />
          }
          label={status.charAt(0).toUpperCase() + status.slice(1)} // Capitalize the label
        />
      ))}
    </div>
  );
};

export default EventFilter;
