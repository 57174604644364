import { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
// import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import dayjs from 'dayjs';
import { format } from 'date-fns';

// project imports
import ColorPalette from './ColorPalette';

// assets
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';

// constant
const getInitialValues = (event, range, isCreating) => {
  const newEvent = {
    title: '',
    description: '',
    status: isCreating ? 'Not started' : '',
    allDay: false,
    start: range ? new Date(range.start) : new Date(),
    end: range ? new Date(range.end) : new Date(),
    recurring: false,
    recurrenceType: null
  };

  if (event || range) {
    return _.merge({}, newEvent, event);
  }

  return newEvent;
};

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

const AddEventForm = ({ event, range, handleDelete, handleCreate, handleUpdate, onCancel }) => {
  const theme = useTheme();
  const isCreating = !event;

  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState('');

  const status = [
    {
      value: theme.palette.primary.main,
      color: 'primary.main',
      label: 'Not started'
    },
    {
      value: theme.palette.warning.light,
      color: 'warning.light',
      label: 'In progress'
    },
    {
      value: theme.palette.success.light,
      color: 'success.light',
      label: 'Finished'
    },
    // {
    //   value: theme.palette.error.main,
    //   color: 'error.main'
    // },
    // {
    //   value: theme.palette.success.dark,
    //   color: 'success.dark'
    // },
    // {
    //   value: theme.palette.secondary.main,
    //   color: 'secondary.main'
    // },
    // {
    //   value: theme.palette.warning.dark,
    //   color: 'warning.dark'
    // },
    // {
    //   value: theme.palette.orange.dark,
    //   color: 'orange.dark'
    // },
    // {
    //   value: theme.palette.grey[500],
    //   color: 'grey.500'
    // },
    // {
    //   value: theme.palette.primary.light,
    //   color: 'primary.light'
    // },
    // {
    //   value: theme.palette.error.light,
    //   color: 'error.light'
    // },
    // {
    //   value: theme.palette.secondary.light,
    //   color: 'secondary.light'
    // },
    // {
    //   value: theme.palette.orange.light,
    //   color: 'orange.light'
    // }
  ];

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
    end: Yup.date().when('start', (start, schema) => start && schema.min(start, 'End date must be later than start date')),
    start: Yup.date(),
    color: Yup.string().max(255),
  });

  const formik = useFormik({
    initialValues: getInitialValues(event, range, isCreating),
    validationSchema: EventSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const data = {
          title: values.title,
          description: values.description,
          status: values.status,
          allDay: values.allDay,
          start: values.start,
          end: values.end,
          recurring: values.recurring,
          recurrenceType: values.recurrenceType
        };

        if (event) {
          handleUpdate(event.id, data);
        } else {
          handleCreate(data);
        }

        resetForm();
        onCancel();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const handleStartDateChange = (newDate) => {
    const formattedDate = format(newDate, 'dd/MM/yyyy hh:mm a');
    console.log('Formatted Start Date:', formattedDate);
    setFieldValue('start', newDate);  // Update Formik value for 'start'
  };

  const handleEndDateChange = (newDate) => {
    const formattedDate = format(newDate, 'dd/MM/yyyy hh:mm a');
    console.log('Formatted End Date:', formattedDate);
    setFieldValue('end', newDate);  // Update Formik value for 'end'
  };

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{event ? 'Edit Event' : 'Add Event'}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  disabled={event?.status === 'job'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Description"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  disabled={event?.status === 'job'}
                />
              </Grid>

              {/* New toggle for Recurring event */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={values.recurring} {...getFieldProps('recurring')} />}
                  label="Recurring Event"
                  disabled={event?.status === 'job'}
                />
              </Grid>

              {/* Conditionally render recurrence options */}
              {(values.recurring) && (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="recurrence"
                      name="recurrence"
                      value={values.recurrenceType}
                      {...getFieldProps('recurrenceType')}
                    // onChange={(e) => setRecurrenceType(e.target.value)}
                    >
                      <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                      <FormControlLabel value="quarterly" control={<Radio />} label="Quarterly" />
                      <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={values.allDay} {...getFieldProps('allDay')} />}
                  label="All day"
                  disabled={event?.status === 'job'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MobileDateTimePicker
                  label="Start date"
                  value={dayjs(values.start).toDate()}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  onChange={handleStartDateChange}
                  disabled={event?.status === 'job'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DateRangeIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MobileDateTimePicker
                  label="End date"
                  value={dayjs(values.end).toDate()}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  onChange={handleEndDateChange}
                  disabled={event?.status === 'job'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={Boolean(touched.end && errors.end)}
                      helperText={touched.end && errors.end}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DateRangeIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Status</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="status"
                        value={values.status}
                        {...getFieldProps('status')}
                        onChange={(e) => setFieldValue('status', e.target.value)}
                        name="status-radio-buttons-group"
                        sx={{ '& .MuiFormControlLabel-root': { mr: 0 } }}
                      >
                        {status.map((item, index) => (
                          <ColorPalette
                            key={index}
                            value={item.label}
                            color={item.color}
                            label={item.label}
                            disabled={event?.status === 'job'}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ p: 3 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {!isCreating && (
                  <Tooltip title="Delete Event" disabled={event?.status === 'job'}>
                    <IconButton onClick={() => handleDelete(event?.id)} size="large" >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button type="button" variant="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                  {!isCreating && event?.status !== 'job' && (
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      {'Edit'}
                    </Button>
                  )}
                  {isCreating && (
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      {'Add'}
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
};

AddEventForm.propTypes = {
  event: PropTypes.object,
  range: PropTypes.object,
  handleDelete: PropTypes.func,
  handleCreate: PropTypes.func,
  handleUpdate: PropTypes.func,
  onCancel: PropTypes.func
};

export default AddEventForm;
