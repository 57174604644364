import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const currentRoute = window.location.pathname;

  // useEffect(() => {
  //   console.log(currentRoute);
  //   console.log(isLoggedIn);
  // }, [currentRoute, isLoggedIn]);

  //if not logged in, redirect to login page
  //if logged in, send to invitation code page, regardless of email verification
  //at some point show email verification page
  //if verified, send tokens? - can't do this here, it's a route guard
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
      // } else if (user.emailVerified === false) {
      //   navigate('email-verification');
    }
  }, [isLoggedIn, user, navigate]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
