import { lazy } from 'react';

// project imports
import MainLayout from 'MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';


import AdminGuard from 'utils/route-guard/AdminGuard';
import EmployeeTable from "EmployeeTable";
import Employee from "Employee";
import Calendar from "views/calendar/index";
import Calendar2 from "calendar";
import StopwatchForm from "StopwatchForm";
import Download from 'download';

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/home',
      element: (
        <AuthGuard>
          <StopwatchForm />
        </AuthGuard>
      ),
    },
    {
      path: '/employees',
      element: (
        <AuthGuard>
          <EmployeeTable />
        </AuthGuard>
      ),
    },
    {
      path: '/employee/:id',
      element: (
        <AuthGuard>
          <Employee />
        </AuthGuard>
      ),
    },
    {
      path: '/tasks',
      element: (
        <AuthGuard>
          <Calendar />
        </AuthGuard>
      ),
    },
    {
      path: '/tasks2',
      element: (
        <AuthGuard>
          <Calendar2 />
        </AuthGuard>
      ),
    },
    {
      path: '/download',
      element: (
        <AuthGuard>
          <Download />
        </AuthGuard>
      ),
    },
  ]
};
export default MainRoutes;