import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Routes from 'routes';

import logo from './logo.svg';

import MainLayout from './MainLayout';
import { LocaleProvider } from './contexts/LocaleContext';
import Locales from './Locales';
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { Provider } from 'react-redux'

import { ThemeProvider } from '@mui/material/styles'; // Import ThemeProvider from MUI

import themes from 'themes'; // Import your theme

function App() {
  return (
    <ThemeProvider theme={themes}>
      <LocaleProvider>
        <Locales>
          <AuthProvider>
            <div id="root" >
              <Routes />
              {/* <RouterProvider router={router} /> */}
            </div>
          </AuthProvider>
        </Locales>
      </LocaleProvider >
    </ThemeProvider>
  );
}



export default App;
