import SelectLocale from './helpers/SelectLocale';
import { Grid, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { useIntl } from 'react-intl'; // Import the useIntl hook

function Header() {
  const intl = useIntl();

  function localeFormat(id) {
    return intl.formatMessage({ id });
  }

  const { logout, user } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <header className="App-header">
      <Grid container spacing={2} pl={3} pt={3}>
        <Grid item xs={2}>
          <Link style={{ color: '#000' }} to="/"><h1>ChronoTrack</h1></Link>
        </Grid>
        <Grid item xs={10}>

          {user && (
            <>
              <Box sx={{ display: 'inline-block', float: 'right' }} mr={3} mt={4}>
                <Button onClick={handleLogout} sx={{ fontWeight: 'bold', float: 'right' }}>{localeFormat('Log out')}</Button>
              </Box>
              <Box sx={{ display: 'inline-block', float: 'right', verticalAlign: 'middle', lineHeight: '60px' }} mr={3} mt={2}>
                <Link style={{ color: '#000' }} to="/tasks">{localeFormat('Tasks')}</Link>
              </Box>
              <Box sx={{ display: 'inline-block', float: 'right', verticalAlign: 'middle', lineHeight: '60px' }} mr={3} mt={2}>
                <Link style={{ color: '#000' }} to="/employees">{localeFormat('Employees')}</Link>
              </Box>
            </>
          )}
          <Box sx={{ display: 'inline-block', float: 'right' }} mr={3} mt={2}>
            <SelectLocale />
          </Box>
        </Grid>

      </Grid>
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
    </header>
  );
}

export default Header;