import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Modal, Box, Button, Typography, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const MyCalendar = () => {
  const [events, setEvents] = useState([
    {
      title: 'Meeting with Team',
      start: new Date(),
      end: new Date(new Date().setHours(new Date().getHours() + 1)),
      description: 'Discuss project updates',
      completed: false,
      subtasks: [{ title: 'Prepare agenda', completed: false }],
    },
    {
      title: 'Doctor Appointment',
      start: new Date(new Date().setDate(new Date().getDate() + 1)),
      end: new Date(new Date().setDate(new Date().getDate() + 1)).setHours(new Date().getHours() + 1),
      description: 'Annual check-up',
      completed: true,
      subtasks: [],
    },
  ]);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskStart, setTaskStart] = useState(new Date());
  const [taskEnd, setTaskEnd] = useState(new Date());
  const [subtaskTitle, setSubtaskTitle] = useState('');

  // Function to handle event click
  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setModalOpen(true);
  };

  // Function to close the event modal
  const closeModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  // Function to handle task creation
  const handleCreateTask = () => {
    const newEvent = {
      title: taskTitle,
      start: taskStart,
      end: taskEnd,
      description: taskDescription,
      completed: false,
      subtasks: [],
    };
    setEvents((prevEvents) => [...prevEvents, newEvent]);
    setTaskTitle('');
    setTaskDescription('');
    setTaskStart(new Date());
    setTaskEnd(new Date());
    setCreateModalOpen(false);
  };

  // Function to delete the selected task
  const handleDeleteTask = () => {
    setEvents((prevEvents) => prevEvents.filter(event => event !== selectedEvent));
    closeModal();
  };

  // Function to toggle completion status of the task
  const toggleTaskCompletion = () => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event === selectedEvent ? { ...event, completed: !event.completed } : event
      )
    );
  };

  // Function to add a subtask
  const handleAddSubtask = () => {
    const newSubtask = { title: subtaskTitle, completed: false };
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event === selectedEvent
          ? { ...event, subtasks: [...event.subtasks, newSubtask] }
          : event
      )
    );
    setSubtaskTitle('');
  };

  return (
    <div>
      <Button variant="contained" onClick={() => setCreateModalOpen(true)} sx={{ margin: '20px' }}>
        Create Task
      </Button>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        events={events.map(event => ({
          ...event,
          color: event.completed ? 'green' : 'blue' // Change color based on completion
        }))}
        eventClick={handleEventClick} // Handle event click
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        style={{ margin: '20px' }}
      />

      {/* MUI Modal for event details */}
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px', // Rounded corners
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Event Details
          </Typography>
          {selectedEvent && (
            <div>
              <Typography variant="h6">{selectedEvent.title}</Typography>
              <Typography>Description: {selectedEvent.description}</Typography>
              <Typography>Start: {selectedEvent.start.toString()}</Typography>
              <Typography>End: {selectedEvent.end.toString()}</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedEvent.completed}
                    onChange={toggleTaskCompletion}
                  />
                }
                label="Completed"
              />
              <Typography variant="subtitle1" sx={{ mt: 2 }}>Subtasks:</Typography>
              {/* {selectedEvent?.subtasks && ( */}

              {
                selectedEvent?.subtasks?.map((subtask, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={subtask.completed}
                        onChange={() => {
                          const updatedSubtasks = selectedEvent.subtasks.map((st) =>
                            st === subtask ? { ...st, completed: !st.completed } : st
                          );
                          setEvents((prevEvents) =>
                            prevEvents.map((event) =>
                              event === selectedEvent ? { ...event, subtasks: updatedSubtasks } : event
                            )
                          );
                        }}
                      />
                    }
                    label={subtask.title}
                  />
                ))
              }
              {/* )} */}
              <TextField
                label="Add Subtask"
                variant="outlined"
                fullWidth
                value={subtaskTitle}
                onChange={(e) => setSubtaskTitle(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Button variant="contained" onClick={handleAddSubtask} sx={{ mt: 2 }}>
                Add Subtask
              </Button>
              <Button variant="contained" color="error" onClick={handleDeleteTask} sx={{ mt: 2 }}>
                Delete Task
              </Button>
            </div>
          )}
          <Button variant="contained" onClick={closeModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>

      {/* MUI Modal for creating tasks */}
      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        aria-labelledby="create-task-title"
        aria-describedby="create-task-description"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              borderRadius: '10px', // Rounded corners
            }}
          >
            <Typography id="create-task-title" variant="h6" component="h2">
              Create Task
            </Typography>
            <TextField
              label="Task Title"
              variant="outlined"
              fullWidth
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              sx={{ mt: 2 }}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              value={taskDescription}
              onChange={(e) => setTaskDescription(e.target.value)}
              sx={{ mt: 2 }}
            />
            <DateTimePicker
              label="Start Date and Time"
              value={taskStart}
              onChange={(newValue) => setTaskStart(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ mt: 2 }} />}
            />
            <DateTimePicker
              label="End Date and Time"
              value={taskEnd}
              onChange={(newValue) => setTaskEnd(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ mt: 2 }} />}
            />
            <Button variant="contained" onClick={handleCreateTask} sx={{ mt: 2 }}>
              Add Task
            </Button>
            <Button variant="outlined" onClick={() => setCreateModalOpen(false)} sx={{ mt: 2, ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </LocalizationProvider>
      </Modal>
    </div>
  );
};

export default MyCalendar;
